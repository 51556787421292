import { AiOutlineDown, AiOutlineRight } from "react-icons/ai";
import { MdOutlineEuroSymbol } from "react-icons/md";
import { GoInfo } from "react-icons/go";
import { BsInfoSquare } from "react-icons/bs";
import { FaCarCrash, FaHistory } from "react-icons/fa";
import { TiSocialDribbbleCircular } from "react-icons/ti";
import "./CarDetails.css";
import GalleryCarousel from "./GalleryCarousel";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../Context/AuthContext";
import Protector from "../../Protector/Protector";
import {
  Navigate,
  unstable_HistoryRouter,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  ActiveAuctionCarDetails,
  AddNewBid,
  GetPriceList,
  webControls,
} from "../../../endpoints";
import CountDownTimer from "../CountDownTimer/CountDownTimer";
import { Input, Skeleton, Spin } from "antd";
import toast from "react-hot-toast";
import DamagesCarousel from "./DamagesCarousel";
import { LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { BiAlarmExclamation } from "react-icons/bi";
import { ContactContext } from "../../../Context/ContactInfoContext";

function CarDetails() {
  const [showFees, setShowFees] = useState(false);
  const [carDetails, setCarDetails] = useState();
  const [isReady, setIsReady] = useState(false);
  const [bidValue, setBidValue] = useState(0);
  const [transportationCost, setTransportationCost] = useState();
  const [bankFee, setBankFee] = useState();
  const [serviceFee, setServiceFee] = useState();
  const [auctionServicefeeOver10k, setauctionServicefeeOver10k] = useState();
  const isLoggedIn = useContext(AuthContext);
  const [inputError, setInputError] = useState();
  const [sliderImagesDamages, setSliderImagesDamages] = useState([]);
  const [showSlider, setShowSlider] = useState(false);
  const [modifiedSliderURLs, setModifiedSliderURLs] = useState([]);
  const [modifiedDamagesURLs, setModifiedDamagesURLs] = useState([]);
  const [modifiedFinishedDate, setModifiedFinishedDate] = useState();
  const [startingDate, setStartingDate] = useState();

  const [isRequestBeingMade, setIsRequestBeingMade] = useState(false);

  const currentTime = new Date();
  // const baseImgUrl = process.env.REACT_APP_IMAGE_URL;
  const { id } = useParams();
  const { t } = useTranslation();

  const { data } = useContext(ContactContext);

  const getTelValueByKey = (key) => {
    const keyData = data.find((item) => item.key === key);
    if (keyData) {
      return keyData.value;
    }
    return null;
  };

  useEffect(() => {
    ActiveAuctionCarDetails(id).then((res) => {
      const carDetails = res.data;
      const finishingDateTime = new Date(carDetails.finishingDateTime);
      setStartingDate(new Date(carDetails.startDate));

      if (!isNaN(finishingDateTime)) {
        const updatedDay = String(finishingDateTime.getDate()).padStart(2, "0");
        const updatedMonth = String(finishingDateTime.getMonth() + 1).padStart(
          2,
          "0"
        );
        const updatedYear = finishingDateTime.getFullYear();
        const updatedHours = String(finishingDateTime.getHours()).padStart(
          2,
          "0"
        );
        const updatedMinutes = String(finishingDateTime.getMinutes()).padStart(
          2,
          "0"
        );

        const updatedFinishingDateTime = `${updatedMonth}/${updatedDay}/${updatedYear} ${updatedHours}:${updatedMinutes}`;

        const startingDateTime = new Date(carDetails.startDate);

        const updatedStartingDateTime = `${String(
          startingDateTime.getDate()
        ).padStart(2, "0")}/${String(startingDateTime.getMonth() + 1).padStart(
          2,
          "0"
        )}/${startingDateTime.getFullYear()} ${String(
          startingDateTime.getHours()
        ).padStart(2, "0")}:${String(startingDateTime.getMinutes()).padStart(
          2,
          "0"
        )}`;

        const updatedCarDetails = {
          ...carDetails,
          finishingDateTime: updatedFinishingDateTime,
          startDate:
            carDetails.startDate == null
              ? carDetails.startDate
              : updatedStartingDateTime,
        };
        const date = new Date(updatedFinishingDateTime);
        setModifiedFinishedDate(date);

        setCarDetails(updatedCarDetails);
        setModifiedSliderURLs(
          updatedCarDetails?.sliderImages?.map(
            (url) => `${url.replace(/\\/g, "/")}`
          )
        );
        // setModifiedDamagesURLs(
        //   updatedCarDetails?.damageImages?.map(
        //     (url) => `${baseImgUrl}${url.replace(/\\/g, "/")}`
        //   )
        // );
        setModifiedDamagesURLs(
          updatedCarDetails?.damageImages?.map((url) => {
            if (url?.includes("motortrade-no-photo.svg")) {
              return "https://remktg.arval.com/buyer/assets/images/motortrade/motortrade-no-photo.svg";
            } else {
              return `${url.replace(/\\/g, "/")}`;
            }
          })
        );
        setIsReady(true);
      } else {
        console.error(
          "Invalid finishingDateTime:",
          carDetails.finishingDateTime
        );
      }
    });
    GetPriceList().then((res) => {
      setTransportationCost(res.data.transport);
      setBankFee(res.data.bankFee);
      setServiceFee(res.data.auctionServiceFee);
      setauctionServicefeeOver10k(res.data.auctionServicefeeOver10k);
    });
  }, []);

  const handleClick = () => {
    setShowFees(!showFees);
  };

  const handleChangeBidValue = (e) => {
    const inputValue = e.target.value;
    const parsedValue = parseInt(inputValue) || 0;
    setBidValue(parsedValue);
    if (parsedValue < parseInt(carDetails?.bidAmount) + 100) {
      setInputError("error-input");
    } else {
      setInputError("");
    }
  };

  const handleSubmitBid = (e) => {
    e.preventDefault();
    if (bidValue < parseInt(carDetails?.bidAmount) + 100) {
      toast.error(
        t(
          "Please enter a bid that is at least €100 more than the previous bid"
        ),
        {
          duration: 3000,
          id: "bidupdatefailed",
        }
      );
    } else if (
      carDetails?.startingPrice &&
      bidValue <= carDetails?.startingPrice
    ) {
      toast.error(
        `${t("Bid must exceed")} €${carDetails.startingPrice}. ${t(
          "Please enter a higher amount."
        )}`,
        {
          duration: 3000,
          id: "bidupdatefailed",
        }
      );
    } else if (bidValue == 0) {
      toast.error(t("Bid should be more than €0!"), {
        duration: 3000,
        id: "bidupdatefailed",
      });
    } else {
      try {
        setIsRequestBeingMade(true);
        AddNewBid(carDetails.id, bidValue).then((res) => {
          if (res.data.success) {
            toast.success(res.data.message, {
              id: "bidadded",
            });
            setBidValue(0);
            setCarDetails((prev) => ({ ...prev, bidAmount: bidValue }));
          } else {
            if (
              res.data.message.toLowerCase().includes("bid should be higher")
            ) {
              toast.error(t("Bid should be higher"), {
                duration: 3000,
                id: "bidaddedfailed",
              });
            } else {
              toast.error(res.data.message, {
                duration: 3000,
                id: "bidaddedfailed",
              });
            }
          }
          setIsRequestBeingMade(false);
        });
      } catch (error) {
        console.error("Error adding bid:", error);
      }
    }
  };

  const renderContent = (children) => {
    if (carDetails.startDate) {
      const [day1, month1, year1, hours1, minutes1] =
        carDetails.startDate?.split(/[\s\/:]/);
      const startingDate1 = new Date(year1, month1 - 1, day1, hours1, minutes1);

      if (startingDate1 > currentTime) {
        return children;
      }
    }

    return null;
  };

  useEffect(() => {
    const carDamagesDiv = document.querySelector(".car-damages");
    if (carDamagesDiv) {
      const htmlContent = carDetails?.damages;

      const tempContainer = document.createElement("div");
      tempContainer.innerHTML = htmlContent;

      const imgElements = tempContainer.querySelectorAll("img.img-fluid");

      imgElements.forEach((img, index) => {
        if (index < modifiedDamagesURLs.length) {
          img.src = modifiedDamagesURLs[index];
        }
      });
      carDamagesDiv.innerHTML = tempContainer.innerHTML;
    }

    const elements = document.querySelectorAll(".swiper-slide");
    elements.forEach((element) => {
      element.addEventListener("click", () => {
        const parent = element.closest(".swiper-wrapper");
        const imgElements = parent.querySelectorAll("img.img-fluid");

        const imageSrcs = Array.from(imgElements)?.map((img) => img.currentSrc);
        setSliderImagesDamages(imageSrcs);
        setShowSlider(true);
      });
    });

    const showAllDamageImagesEl = document.querySelector(".car-damages u");
    showAllDamageImagesEl?.addEventListener("click", () => {
      setSliderImagesDamages(modifiedDamagesURLs);
      setShowSlider(true);
    });
  }, [modifiedDamagesURLs]);

  const navigate = useNavigate();

  const handleBackNavigate = () => {
    const canNavigateBack = window.history.length > 2;

    if (canNavigateBack) {
      navigate(-1);
    } else {
      navigate("/auctions/opened");
    }
  };

  return (
    <Protector
      isLoggedIn={webControls.isCarmax ? isLoggedIn.isLoggedIn : true}
      error={
        <div className="my-bids-error">
          {t("You don't have access to this path!")}
        </div>
      }
    >
      <div className="car-details-bg">
        <span
          onClick={handleBackNavigate}
          className="container"
          style={{
            justifyContent: "flex-start",
            color: "#336699",
            fontWeight: 500,
            paddingTop: "3rem",
            cursor: "pointer",
            fontSize: "17px",
          }}
        >
          &lt; {t("Back")}
        </span>
        <div className="container">
          {isReady ? (
            <div
              className="car-details-container"
              style={{ marginTop: "1rem" }}
            >
              <div className="car-details-container__header">
                <p>
                  <BiAlarmExclamation />{" "}
                  {carDetails?.startDate != null &&
                    renderContent(
                      <span>
                        {t("STARTS ON")} {carDetails?.startDate} {t("AND")}{" "}
                      </span>
                    )}
                  {currentTime > modifiedFinishedDate
                    ? t("ENDED ON")
                    : t("ENDS ON")}
                  : {carDetails?.finishingDateTime?.substring(0, 10)}
                </p>
                <CountDownTimer
                  finishingDateTime={carDetails?.finishingDateTime}
                />
              </div>
              <div className="car-details">
                <div className="car-details-title">
                  <h2>{carDetails?.name}</h2>
                  <div className="car-details-title__img">
                    <GalleryCarousel sliderImages={modifiedSliderURLs} />
                  </div>
                  <div className="inline-links">
                    <span>
                      <BsInfoSquare size={17} style={{ marginRight: "5px" }} />{" "}
                      <a href="#car-details" className="text-dark">
                        {t("Details")}
                      </a>
                    </span>
                    <span>
                      <FaCarCrash size={20} style={{ marginRight: "5px" }} />
                      <a href="#damages" className="text-dark">
                        {t("Damages")}
                      </a>
                    </span>
                    <span>
                      <FaHistory size={17} style={{ marginRight: "5px" }} />
                      <a href="#maintenance-history" className="text-dark">
                        {t("Maintenance History")}
                      </a>
                    </span>
                    <span>
                      <TiSocialDribbbleCircular
                        size={20}
                        style={{ marginRight: "5px" }}
                      />
                      <a href="#tyres" className="text-dark">
                        {t("Tyres")}
                      </a>
                    </span>
                  </div>
                </div>
                <div className="car-details-info">
                  {startingDate > currentTime ? (
                    <div className="vat-excluded">{t("VAT excluded")}</div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        gap: "0.75rem",
                        flexWrap: "wrap",
                      }}
                    >
                      {(carDetails?.startingPrice ||
                        carDetails?.startingPrice !== 0) && (
                        <span className="starting-price">
                          {t("Start Price")}:{" "}
                          <span>
                            {" "}
                            {carDetails?.startingPrice?.toLocaleString()}€
                          </span>
                        </span>
                      )}

                      <div className="car-details-info__inputAndButton">
                        <label className="car-details-label" htmlFor="bid">
                          <div className="car-details-label__text">
                            <span>{t("Your bid")}:</span>
                            <span>{t("Bid price is excluding VAT")}</span>
                          </div>
                          <Input
                            disabled={
                              modifiedFinishedDate < currentTime ||
                              (!webControls.isCarmax && !isLoggedIn.isLoggedIn)
                            }
                            className={inputError}
                            style={{ width: "100%" }}
                            prefix={<MdOutlineEuroSymbol />}
                            type="number"
                            name="bidValue"
                            placeholder={
                              !webControls.isCarmax &&
                              !isLoggedIn.isLoggedIn &&
                              "Nuk keni akses!"
                            }
                            id="bid"
                            size="large"
                            min={
                              carDetails?.bidAmount
                                ? parseInt(carDetails?.bidAmount) + 100
                                : 0
                            }
                            onChange={(e) => {
                              handleChangeBidValue(e);
                            }}
                            value={bidValue == 0 ? null : bidValue}
                            step="10"
                          />
                        </label>

                        <button
                          onClick={handleSubmitBid}
                          style={{
                            opacity:
                              modifiedFinishedDate < currentTime ||
                              (!webControls.isCarmax && !isLoggedIn.isLoggedIn)
                                ? "0.65"
                                : "1",
                            cursor:
                              modifiedFinishedDate < currentTime ||
                              (!webControls.isCarmax && !isLoggedIn.isLoggedIn)
                                ? "not-allowed"
                                : "pointer",
                          }}
                          disabled={
                            isRequestBeingMade ||
                            modifiedFinishedDate < currentTime ||
                            (!webControls.isCarmax && !isLoggedIn.isLoggedIn)
                          }
                        >
                          {isRequestBeingMade ? (
                            <Spin
                              style={{ color: "white" }}
                              size="small"
                              indicator={
                                <LoadingOutlined
                                  style={{
                                    fontSize: 24,
                                  }}
                                  spin
                                />
                              }
                            />
                          ) : (
                            t("Submit bid")
                          )}
                        </button>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {/* {(carDetails?.startingPrice ||
                          carDetails?.startingPrice !== 0) && (
                          <span className="starting-price">
                            Start Price:{" "}
                            <span> {carDetails?.startingPrice}€</span>
                          </span>
                        )} */}
                          {carDetails?.bidAmount !== 0 && (
                            <span className="max-bid-amount">
                              <GoInfo /> {t("Your Bid")}:{" "}
                              {carDetails?.bidAmount
                                ? `${carDetails?.bidAmount}€`
                                : "N/A"}
                            </span>
                          )}
                        </div>
                        {!webControls.isCarmax && !isLoggedIn.isLoggedIn && (
                          <span className="max-bid-amount">
                            Për të ofruar bid, ju lutemi kyçuni!
                          </span>
                        )}
                      </div>
                    </div>
                  )}

                  <div className="car-details-fee">
                    <div
                      className="car-details-fee__title"
                      onClick={handleClick}
                    >
                      {t("Show all fees excluding VAT")}{" "}
                      {!showFees ? <AiOutlineRight /> : <AiOutlineDown />}
                    </div>
                    <div
                      className={`grid-control ${
                        showFees ? "grid-expand" : ""
                      }`}
                    >
                      <div className="car-details-fee__container">
                        <div className="car-details-fee__content">
                          <span>{t("Vehicle")}</span>
                          <span>€ {bidValue}</span>
                        </div>
                        {webControls.isCarmax ? (
                          <div className="car-details-fee__content">
                            <span
                              className="width-fix"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {t("Transportation cost to delivery location")}
                              {(carDetails?.transport == null ||
                                carDetails?.transport == 0) && (
                                <span
                                  style={{
                                    color: "red",
                                    fontSize: "12px",
                                    marginTop: "0.4rem",
                                    fontWeight: "600",
                                  }}
                                >
                                  ({t("Contact administrator")})
                                </span>
                              )}
                            </span>
                            <span>
                              €{" "}
                              {carDetails?.transport == null ||
                              carDetails?.transport == 0
                                ? 0
                                : carDetails?.transport}
                            </span>
                          </div>
                        ) : (
                          <div className="car-details-fee__content">
                            <span
                              className="width-fix"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {t("Transportation cost to delivery location")}

                              <span
                                style={{
                                  color: "red",
                                  fontSize: "12px",
                                  marginTop: "0.4rem",
                                  fontWeight: "600",
                                  maxWidth: "90%",
                                }}
                              >
                                (Kosto e transportit sillet prej € 800 deri €
                                1200. Për te marr informatën e saktë, kontaktoni
                                administratorin {getTelValueByKey("Tel")})
                              </span>
                            </span>
                            <span>€0</span>
                          </div>
                        )}
                        <div className="car-details-fee__content">
                          <span>{t("Bank fee")}</span>
                          <span>€ {bankFee}</span>
                        </div>
                        <span className="car-details-fee__line"></span>
                        <div className="car-details-fee__content fee-content__total">
                          <span>{t("Sub total")}:</span>
                          <span>
                            €{" "}
                            {parseInt(bidValue) +
                              parseInt(carDetails?.transport || 0) +
                              parseInt(bankFee)}
                          </span>
                        </div>
                        <div className="car-details-fee__content">
                          <span>{t("Auction service fee")}*</span>
                          <span>
                            €{" "}
                            {/* {(bidValue < 20000 &&
                            carDetails?.startingPrice < 20000
                              ? isLoggedIn.data.minAuctionFee !== null
                                ? isLoggedIn.data.minAuctionFee
                                : serviceFee
                              : isLoggedIn.data.maxAuctionFee !== null
                              ? isLoggedIn.data.maxAuctionFee
                              : auctionServicefeeOver10k
                            ).toFixed(0)} */}
                            {(
                              (bidValue < 20000 &&
                              carDetails?.startingPrice < 20000
                                ? isLoggedIn.data.minAuctionFee !== null
                                  ? isLoggedIn.data.minAuctionFee
                                  : serviceFee
                                : isLoggedIn.data.maxAuctionFee !== null
                                ? isLoggedIn.data.maxAuctionFee
                                : auctionServicefeeOver10k) || 0
                            )?.toFixed(0)}
                          </span>
                        </div>
                        <span className="car-details-fee__line"></span>
                        <div className="car-details-fee__content fee-content__total">
                          <span>{t("Total excluding VAT")}:</span>
                          <span>
                            €{" "}
                            {parseInt(bidValue) +
                              parseInt(
                                carDetails?.transport == null ||
                                  carDetails?.transport == 0
                                  ? 0
                                  : carDetails?.transport
                              ) +
                              parseInt(bankFee) +
                              (bidValue < 20000
                                ? isLoggedIn.data.minAuctionFee !== null
                                  ? isLoggedIn.data.minAuctionFee
                                  : serviceFee
                                : isLoggedIn.data.maxAuctionFee !== null
                                ? isLoggedIn.data.maxAuctionFee
                                : auctionServicefeeOver10k)}
                          </span>
                        </div>
                        <p>
                          *
                          {t(
                            "The auction service fee includes the operator fee, auction fee, vehicle and document preparation"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="car-details-info-about-car">
                    {carDetails?.brand && (
                      <>
                        <div className="car-details-info-about-car__content">
                          <span className="details-title">{t("Brand")}</span>
                          <span>{carDetails?.brand}</span>
                        </div>
                        <span className="car-details-info-about-car__content-line"></span>
                      </>
                    )}

                    {carDetails?.registrationMonth && (
                      <>
                        {" "}
                        <div className="car-details-info-about-car__content">
                          <span className="details-title">
                            {t("First registration date")}
                          </span>
                          <span>
                            {carDetails?.registrationMonth},{" "}
                            {carDetails?.registrationDate}
                          </span>
                        </div>
                        <span className="car-details-info-about-car__content-line"></span>
                      </>
                    )}

                    {carDetails?.petrol && (
                      <>
                        {" "}
                        <div className="car-details-info-about-car__content">
                          <span className="details-title">{t("Fuel")}</span>
                          <span>{carDetails?.petrol}</span>
                        </div>
                        <span className="car-details-info-about-car__content-line"></span>
                      </>
                    )}

                    {carDetails?.transmission && (
                      <>
                        <div className="car-details-info-about-car__content">
                          <span className="details-title">{t("Gearbox")}</span>
                          <span>{carDetails?.transmission}</span>
                        </div>
                        <span className="car-details-info-about-car__content-line"></span>
                      </>
                    )}

                    {carDetails?.engineCapacity && (
                      <>
                        <div className="car-details-info-about-car__content">
                          <span className="details-title">
                            {t("Engine capacity")}
                          </span>
                          <span>
                            {carDetails?.engineCapacity?.toLocaleString()}{" "}
                            <span style={{ whiteSpace: "nowrap" }}>
                              cm<sup style={{ fontSize: "0.6rem" }}>3</sup>
                            </span>
                          </span>
                        </div>
                        <span className="car-details-info-about-car__content-line"></span>
                      </>
                    )}

                    {carDetails?.hp && (
                      <>
                        {" "}
                        <div className="car-details-info-about-car__content">
                          <span className="details-title">{t("Power")}</span>
                          <span>{carDetails?.hp?.toLocaleString()} KW</span>
                        </div>
                        <span className="car-details-info-about-car__content-line"></span>
                      </>
                    )}

                    {carDetails?.km && (
                      <>
                        <div className="car-details-info-about-car__content">
                          <span className="details-title">{t("Mileage")} </span>
                          <span>{carDetails?.km?.toLocaleString()} km</span>
                        </div>
                        <span className="car-details-info-about-car__content-line"></span>
                      </>
                    )}

                    {carDetails?.cO2Emission && (
                      <>
                        <div className="car-details-info-about-car__content">
                          <span className="details-title">CO2</span>
                          <span>{carDetails?.cO2Emission}</span>
                        </div>
                        <span className="car-details-info-about-car__content-line"></span>
                      </>
                    )}

                    {carDetails?.greenhouseGasEmissionClass && (
                      <>
                        <div className="car-details-info-about-car__content">
                          <span className="details-title">
                            {t("Green Gas Emission Class")}
                          </span>
                          <span>{carDetails?.greenhouseGasEmissionClass}</span>
                        </div>
                        <span className="car-details-info-about-car__content-line"></span>
                      </>
                    )}

                    {carDetails?.vinNumber && (
                      <>
                        <div className="car-details-info-about-car__content">
                          <span className="details-title">
                            {t("VIN number")}
                          </span>
                          <span>{carDetails?.vinNumber}</span>
                        </div>
                        <span className="car-details-info-about-car__content-line"></span>
                      </>
                    )}

                    {carDetails?.seats && (
                      <>
                        <div className="car-details-info-about-car__content">
                          <span className="details-title">{t("Seats")}</span>
                          <span>{carDetails?.seats}</span>
                        </div>
                        <span className="car-details-info-about-car__content-line"></span>
                      </>
                    )}
                    {carDetails?.noKeys && (
                      <>
                        <div className="car-details-info-about-car__content">
                          <span className="details-title">{t("Keys No")}</span>
                          <span>{carDetails?.noKeys}</span>
                        </div>
                        <span className="car-details-info-about-car__content-line"></span>
                      </>
                    )}
                    <div className="car-details-info-about-car__content">
                      <span className="details-title">
                        {t("Registration Number")}
                      </span>
                      <span>{carDetails?.registrationNumber}</span>
                    </div>
                  </div>
                </div>
              </div>
              {showSlider && (
                <DamagesCarousel
                  sliderImages={sliderImagesDamages}
                  showSlider={showSlider}
                  setShowSlider={setShowSlider}
                />
              )}
              <div
                className="car--details"
                id="car-details"
                dangerouslySetInnerHTML={{
                  __html: carDetails?.details,
                }}
              ></div>
              <div
                id="damages"
                className="car-damages"
                dangerouslySetInnerHTML={{
                  __html: carDetails?.damages,
                }}
              ></div>
              <div
                id="maintenance-history"
                className="car-maintance-history"
                dangerouslySetInnerHTML={{
                  __html: carDetails?.maintanceHistory,
                }}
              ></div>
              <div
                id="tyres"
                className="car-damages"
                dangerouslySetInnerHTML={{
                  __html: carDetails?.tyres,
                }}
              ></div>
            </div>
          ) : (
            <div className="container details-skeleton-containers ">
              <div
                className="auction-list__content"
                style={{ padding: "2rem 0" }}
              >
                <Skeleton active paragraph={false} />
                <div>
                  <Skeleton.Button active={true} size={256} shape={"square"} />
                  <Skeleton
                    active
                    paragraph={{
                      rows: 13,
                    }}
                    title={false}
                  />
                </div>
                <Skeleton
                  active
                  paragraph={{
                    rows: 5,
                  }}
                  title={false}
                />
                <Skeleton
                  active
                  paragraph={{
                    rows: 5,
                  }}
                  title={false}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </Protector>
  );
}

export default CarDetails;
